import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dearMeApis } from "../../API";
import { setError, setLoading } from "../../reused";

export const fetchCountries = createAsyncThunk(
    'order/fetchCountries',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getAllCountries()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loading: false,
        error: null,
        countries: {}
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchCountries.pending, setLoading)
        addCase(fetchCountries.fulfilled, (state, action) => {
            state.countries = action.payload
            state.loading = false
        })
        addCase(fetchCountries.rejected, setError)
    }
})


export default orderSlice.reducer