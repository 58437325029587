import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dearMeApis } from "../../API";
import { setError, setLoading } from "../../reused";

export const fetchBySubCategory = createAsyncThunk(
    'products/fetchBySubCategory',
    async ({ lang, name, list_name }, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getBySubCategory(lang, name, list_name)
            if (res.status !== 200) {
                throw new Error('Server Error, can\'t resolve categories')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchProductById = createAsyncThunk(
    'products/fetchProductById',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const res = await dearMeApis.getProductById(id)
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            dispatch(fetchByRecomend(res?.data?.collection_id))
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchProductsByName = createAsyncThunk(
    'products/fetchProductsByName',
    async (name, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getProductListByName(name)
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByDiscount = createAsyncThunk(
    'products/fetchByDiscount',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getDiscounts()
            if (res.status !== 201) {
                throw new Error('Server Error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByRecomend = createAsyncThunk(
    'products/fetchByNew',
    async (id, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getRecomendList(id)
            if (res.status !== 200) {
                throw new Error('Server Error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        searchArr: [],
        discountArr: [],
        recomend: [],
        product: {},
        error: null,
        loading: false,
    },
    reducers: {
        cleanSearch(state, action) {
            state.searchArr = []
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchBySubCategory.pending, setLoading)
        addCase(fetchBySubCategory.fulfilled, (state, action) => {
            state.loading = false
            state.products = action.payload
        })
        addCase(fetchBySubCategory.rejected, setError)
        addCase(fetchProductById.pending, setLoading)
        addCase(fetchProductById.fulfilled, (state, action) => {
            state.loading = false
            state.product = action.payload
        })
        addCase(fetchProductById.rejected, setError)
        addCase(fetchProductsByName.fulfilled, (state, action) => {
            state.loading = false
            state.searchArr = action.payload
        })
        addCase(fetchProductsByName.rejected, setError)
        addCase(fetchByDiscount.pending, setLoading)
        addCase(fetchByDiscount.fulfilled, (state, action) => {
            state.loading = false
            state.discountArr = action.payload
        })
        addCase(fetchByDiscount.rejected, setError)
        addCase(fetchByRecomend.pending, setLoading)
        addCase(fetchByRecomend.fulfilled, (state, action) => {
            state.loading = false
            state.recomend = action.payload
        })
        addCase(fetchByRecomend.rejected, setError)
    }
})

export const { cleanSearch } = productsSlice.actions

export default productsSlice.reducer