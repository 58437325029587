import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { removeLS, setLS } from "../../localStorage"
import { reduceCount, setError, setLoading } from "../../reused"
import { dearMeApis } from './../../API/index';

export const fetchBySendProducts = createAsyncThunk(
    'cart/fetchBySendProducts',
    async (data, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.sendProducts(data)
            if (res.status !== 201) {
                throw new Error('Server Error, can\'t resolve category')
            }

            return {
                status: true,
                name: res?.data?.name,
                surname: res?.data?.surname
            }
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cartArr: [],
        countInCart: 0,
        status: false,
        name: '',
        surname: '',
        loading: false,
        error: null,
    },
    reducers: {
        setCartArr(state, action) {
            state.cartArr = action.payload
            state.countInCart = reduceCount(action?.payload)
        },
        filterCart(state, action) {
            state.cartArr = state.cartArr.filter(item => item.id_item !== action.payload.id)
            setLS('cart', state.cartArr)
            state.countInCart = reduceCount(state?.cartArr)
        },
        plusCount(state, action) {
            const data = state.cartArr.find(el => el.id_item === action.payload.id)
            if (data) {
                data.count = data.count + 1
                setLS('cart', state?.cartArr)
                state.countInCart = reduceCount(state?.cartArr)
            }
        },
        minusCount(state, action) {
            const data = state.cartArr.find(el => el.id_item === action.payload.id)
            if (data) {
                data.count = data.count - 1
                setLS('cart', state?.cartArr)
                state.countInCart = reduceCount(state?.cartArr)
            }
        },
        allClearInCart(state) {
            state.cartArr = []
            state.countInCart = 0
            removeLS('cart')
            state.status = false
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(fetchBySendProducts.pending, setLoading)
        addCase(fetchBySendProducts.fulfilled, (state, action) => {
            state.status = action.payload.status
            state.name = action.payload.name
            state.surname = action.payload.surname
            state.loading = false
        })
        addCase(fetchBySendProducts.rejected, setError)
    }
})

export const { setCartArr, filterCart, plusCount, minusCount, allClearInCart } = cartSlice.actions

export default cartSlice.reducer