import React from 'react';
import s from './CartCard.module.css'
import gift from '../../../assets/images/gift.png'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filterCart, minusCount, plusCount } from '../../../redux/slices/cartSlice';
import { Link, useLocation } from 'react-router-dom';

const CartCard = ({ el, setIsVisible }) => {
    const { cource_rub } = useSelector(state => state.info.company)
    const { t, i18n } = useTranslation()
    const lang = i18n.language
    const dispatch = useDispatch()
    const location = useLocation()

    const deleteItem = (id) => {
        dispatch(filterCart({ id }))
    }

    const increment = (id) => {
        dispatch(plusCount({ id }))
    }

    const decrement = (id) => {
        dispatch(minusCount({ id }))
    }

    return (
        <>
            {
                el.product === 'certificate' ?
                    <div className={s.card}>
                        <Link onClick={() => location.pathname !== '/order' && setIsVisible(false)} to={'/gift-certificate'}>
                            <div className={s.card_img_block}>
                                <img className={s.img_gift} src={gift} alt="gift" />
                            </div>
                        </Link>
                        <div className={s.card_right_block}>
                            <div className={s.card_info_block}>
                                <h3>{window.screen.width >= 992 ? t("header.gift_certificate") : t("header.gift_certificate").slice(0, 15) + '...'}</h3>
                                <h4 className={s.price}>${el.chooseSum}</h4>
                                <p className={s.info}><span>{t("gift_certificate.from")}</span> {el.name}</p>
                                <p className={s.info}><span>{t("gift_certificate.to")}</span> {el.email}</p>
                                <div onClick={(e) => e.stopPropagation()} className={s.info}>
                                    <span>{t("cart.count")}</span>
                                    <div className={s.count_block}>
                                        <button disabled={el.count <= 1 ? true : false} onClick={() => decrement(el.id_item)}>-</button>
                                        {el.count}
                                        <button onClick={() => increment(el.id_item)}>+</button>
                                    </div>
                                </div>
                            </div>
                            <span className={s.delete_btn} onClick={() => deleteItem(el.id_item)}>{t("cart.delete")}</span>
                        </div>
                    </div>
                    :
                    <div className={s.card}>
                        <Link onClick={() => location.pathname !== '/order' && setIsVisible(false)} to={`/detail-product/${el.id}/${el[`name_${lang}`]}`}>
                            <div className={s.card_img_block}>
                                <div className={s.bg}></div>
                                <img className={s.img_gift} src={el.image} alt={el[`name_${lang}`]} />
                            </div>
                        </Link>
                        <div className={s.card_right_block}>
                            <div className={s.card_info_block}>
                                <h3 title={el[`name_${lang}`]}>
                                    {el[`name_${lang}`].length > 22 ? el[`name_${lang}`]?.slice(0, 20) + '...' : el[`name_${lang}`]}
                                </h3>
                                <h4 className={s.price}>${el.embroidery_price_usd ? el.chooseSum + el.embroidery_price_usd : el.chooseSum} | &#8381;{el.embroidery_price_usd ? el.sumRub + (el.embroidery_price_usd * cource_rub) : el.sumRub}</h4>
                                <p className={s.info}><span>{t("cart.size")}</span> {el.size_name ? el.size_name : t("detail.own_metrics")}</p>
                                <p className={s.info}><span>{t("detail.color")}</span> {el.color_name}</p>
                                <div onClick={(e) => e.stopPropagation()} className={s.info}>
                                    <span>{t("cart.count")}</span>
                                    <div className={s.count_block}>
                                        <button disabled={el.count <= 1 ? true : false} onClick={() => decrement(el.id_item)}>-</button>
                                        {el.count}
                                        <button onClick={() => increment(el.id_item)}>+</button>
                                    </div>
                                </div>
                            </div>
                            <h5 className={s.embro}>{el.embroidery_price_usd ? `${t('detail.name_embro')} ${el.embroidery_price_usd}$` : null}</h5>
                            <span className={s.delete_btn} onClick={() => deleteItem(el.id_item)}>{t("cart.delete")}</span>
                        </div>
                    </div>
            }
        </>
    );
};

export default CartCard;