import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import s from "./mobileSearchBar.module.css";
import searchIcon from "../../../../assets/images/search.png";

const MobileSearchBar = ({
  handleSearch,
  value,
  t,
  setSearchOpen,
  hideSearchBar,
  setValue
}) => {
  const { company } = useSelector((state) => state?.info);
  const handleClose = () => {
    setSearchOpen(false);
    hideSearchBar();
  };
  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.mobile_search_top}>
          <Link to={"/"}>
            <img
              className={s.logo}
              title={`${company?.name} Home`}
              src={`https://dearmelingerie.com:4443${company?.logo}`}
              alt="logo"
            />
          </Link>
          <div className={s.burgerClose} onClick={() => handleClose()}>
            <div className={s.burgerMenuLine}></div>
            <div className={s.burgerMenuLine}></div>
          </div>
        </div>
        <div className={s.search_block}>
          <input
            className={s.search_input}
            onChange={handleSearch}
            value={value}
            type="search"
            placeholder={t("header.search")}
          />
          <button className={s.search_btn}>
            <img src={searchIcon} alt="search" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileSearchBar;
