

export const reduceCount = (arr) => {
    return arr?.reduce((count, el) => {
        count += el.count
        return count
    }, 0)
}

export const validateEmail = (email) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (email.match(validRegex)) {
        return false;
    } else {
        return true;
    }
}

export const setLoading = (state) => {
    state.loading = true
    state.error = null
}

export const setError = (state, action) => {
    state.error = action.payload
    state.loading = false
}

export const checkEmptyKeys = (obj) => {
    let result = Object.values(obj).some((item) => item === '')
    return result
}

export const totalSumInCart = (arr) => {
    const result = arr?.reduce((sum, el) => {
        sum += (el.embroidery_price_usd ? el.chooseSum + el.embroidery_price_usd : el.chooseSum) * el.count
        return sum
    }, 0)
    return result
}

export const totalSumInCartRub = (arr, cource_rub) => {
    const result = arr?.reduce((sum, el) => {
        sum += (el.embroidery_price_usd ? (el.sumRub || el.chooseSum) + (el.embroidery_price_usd * cource_rub) : (el.sumRub || el.chooseSum * cource_rub)) * el.count
        return sum
    }, 0)
    return result
}