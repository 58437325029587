import React, { useState } from "react";
import s from "./navMobile.module.css";
import arrow from "../../../assets/images/arrow-down.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavMobile = ({ open, setOpen }) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  const [showCollection, setShowCollection] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const { categoriesArr, error } = useSelector((state) => state.categories);
  const { collectionsArr } = useSelector(state => state.collections)
  const { company } = useSelector((state) => state?.info);
  const toClose = () => {
    setOpen(false)
  }
  return (
    <div className={`${s.navAccordion} ${open ? s.active : ""}`}>
      <div className={s.logo_block}>
        <Link onClick={toClose} to={"/"}>
          <img
            className={s.logo}
            title={`${company?.name} Home`}
            src={`https://dearmelingerie.com:4443${company?.logo}`}
            alt="logo"
          />
        </Link>
      </div>

      <div className={`${s.panel} ${showCollection ? s.active : s.hidden}`}>
        <div
          className={s.panelHeader}
          onClick={() => setShowCollection(!showCollection)}
        >
          <h6 className={s.navUnderTitle}> {t("header.collections")}</h6>
          <img className={s.panelArrow} src={arrow} alt="arrow" />
        </div>
        <div className={s.navWrapper}>
          {collectionsArr.length > 0 ? (
            collectionsArr?.map((el) => (
              <div className={s.subMenuItems} key={el?.id}>

                <Link
                  onClick={() => toClose()}
                  className={s.subMenu}
                  to={`/products/collection/${el[`name_${lang}`]}/${el.id}`}
                >
                  <h2 className={s.category}>{el[`name_${lang}`]}</h2>
                </Link>
                {/* {el?.sub_categories?.map((item) => (
                  <Link
                    className={s.subMenu}
                    onClick={() => toClose()}
                    to={`/sub_category/category/${el[`name_${lang}`]}/${item[`name_${lang}`]
                      }`}
                    key={item.id}
                  >
                    {item[`name_${lang}`]}
                  </Link>
                ))} */}
              </div>
            ))
          ) : (
            <h1 className={s.category}>{error}</h1>
          )}
        </div>
      </div>

      <div className={`${s.panel} ${showDescription ? s.active : s.hidden}`}>
        <div
          className={s.panelHeader}
          onClick={() => setShowDescription(!showDescription)}
        >
          <h6 className={s.navUnderTitle}> {t("header.categories")}</h6>
          <img className={s.panelArrow} src={arrow} alt="arrow" />
        </div>
        <div className={s.navWrapper}>
          {categoriesArr.length > 0 ? (
            categoriesArr?.map((el) => (
              <div className={s.subMenuItems} key={el?.id}>
                <Link
                  onClick={() => toClose()}
                  className={s.subMenu}
                  to={`/products/category/${el[`name_${lang}`]}/${el.id}`}
                >
                  <h2 className={s.category}>{el[`name_${lang}`]}</h2>
                </Link>
                {/* {el?.sub_categories?.map((item) => (
                  <Link
                    className={s.subMenu}
                    onClick={() => toClose()}
                    to={`/sub_category/category/${el[`name_${lang}`]}/${item[`name_${lang}`]
                      }`}
                    key={item.id}
                  >
                    {item[`name_${lang}`]}
                  </Link>
                ))} */}
              </div>
            ))
          ) : (
            <h1 className={s.category}>{error}</h1>
          )}
        </div>
      </div>

      <div className={s.panel}>
        <Link
          onClick={() => toClose()}
          className={s.navUnderTitle}
          to={"/discounts"}
        >
          <h2 className={s.category}> {t("header.discounts")}</h2>
        </Link>
      </div>

      <div className={s.panel}>
        <Link
          onClick={() => toClose()}
          className={s.navUnderTitle}
          to={"/gift-certificate"}
        >
          <h2 className={s.category}> {t("header.gift_certificate")}</h2>
        </Link>
      </div>
    </div>
  );
};

export default NavMobile;
