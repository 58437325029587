export const setLS = (key, item) => {
    localStorage.setItem(key, JSON.stringify(item))
}

export const getLS = (key) => {
    return localStorage.getItem(key)
}

export const removeLS = (key) => {
    localStorage.removeItem(key)
}