import React, { useEffect, useState } from 'react';
import s from './Footer.module.css'
import slogan_logo from '../../assets/images/footer_logo.png'
import { useTranslation } from 'react-i18next';
import SizesGuide from './../SizesGuide/SizesGuide';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { t } = useTranslation()
    const [showGuide, setShowGuide] = useState(false)

    const hideGuide = (e) => {
        if (e.target.classList[0] === 'close') {
            setShowGuide(false)
        }
        if (e.keyCode === 27) {
            setShowGuide(false)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', hideGuide)
        return () => {
            document.removeEventListener('keydown', hideGuide)
        }
    }, [])

    return (
        <footer>
            <div className={s.container}>
                <div className={s.footer_top_block}>
                    <div className={s.slogan_logo_block}>
                        <img src={slogan_logo} alt="footer_logo" />
                    </div>
                    <nav>
                        <ul className={s.menu}>
                            <li><Link to={'/pages/contacts'}>{t("footer.contacts")}</Link></li>
                            <li onClick={() => setShowGuide(true)}>{t("footer.guide_sizes")}</li>
                            <li><Link to={'/pages/delivery'}>{t("footer.delivery")}</Link></li>
                            <li><Link to={'/pages/payment'}>{t("footer.payment")}</Link></li>
                            <li><Link to={'pages/return'}>{t("footer.return")}</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className={s.footer_bottom_block}>
                    <p>©{new Date().getFullYear()} Design by ITmag Studio</p>
                    <div className={s.bottom_info}>
                        <span><Link to={'/pages/privacy_policy'}>{t("footer.privacy_policy")}</Link></span>
                        {/* <span><Link to={'/pages/conditions'}>{t("footer.conditions_of_use")}</Link></span> */}
                    </div>
                </div>
            </div>
            {showGuide && <SizesGuide show={showGuide} hide={hideGuide} setShowGuide={setShowGuide} />}
        </footer>
    );
};

export default Footer;