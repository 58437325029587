import { createSlice } from "@reduxjs/toolkit";



const favorSlice = createSlice({
    name: 'favorites',
    initialState: {
        favorites: [],
        countInFavor: 0,
    },
    reducers: {
        addToFavorites(state, action) {
            state.favorites = action.payload
            state.countInFavor = action?.payload?.length
        }
    }
})

export const { addToFavorites } = favorSlice.actions


export default favorSlice.reducer