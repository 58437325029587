import React, { useEffect } from 'react';
import Header from './components/Header/Header';
import './App.css'
import Footer from './components/Footer/Footer';
import Main from './components/Main/Main';
import { useDispatch, useSelector } from 'react-redux';
import { getLS } from './localStorage/index'
import { setCartArr } from './redux/slices/cartSlice';
import { fetchCategories } from './redux/slices/categoriesSlice';
import { fetchCollections } from './redux/slices/collectionsSlice';
import { fetchByInfo } from './redux/slices/infoSlice';
import { addToFavorites } from './redux/slices/favorSlice';
import { fetchByPopular } from './redux/slices/homeSlice';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/free-mode";
import 'swiper/swiper-bundle.css';
import { HelmetProvider } from 'react-helmet-async';
import wa from './assets/images/logos_whatsapp-icon.png'

const App = () => {
  const { whatsapp } = useSelector(state => state?.info?.company)
  const dispatch = useDispatch()

  useEffect(() => {
    const data = JSON.parse(getLS('cart')) || []
    if (data !== null || data !== undefined) {
      dispatch(setCartArr(data))
    }
    const favorites = JSON.parse(getLS('whishlist')) || []
    if (favorites !== null || favorites !== undefined) {
      dispatch(addToFavorites(favorites))
    }
    dispatch(fetchCategories())
    dispatch(fetchCollections())
    dispatch(fetchByInfo())
    dispatch(fetchByPopular())
  }, [dispatch])
  return (
    <div className='app_wrapper'>
      <HelmetProvider>
        <Header />
        <Main />
        <Footer />
      </HelmetProvider>
      <a title='link on whatsapp' className="wrapper__link" href={whatsapp}>
        <img src={wa} alt="WhatsApp_icon" />
      </a>
    </div>
  );
};

export default App;