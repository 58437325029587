import React from 'react';
import s from './CartEmpty.module.css'
import empty from '../../../assets/images/empty_img.png'
import { useTranslation } from 'react-i18next';

const CartEmpty = () => {
    const { t } = useTranslation()
    return (
        <div className={s.empty_wrapper}>
            <img className={s.empty_image} src={empty} alt="empty_img" />
            <h2 className={s.empty_text}>{t("cart.empty")}</h2>
        </div>
    );
};

export default CartEmpty;