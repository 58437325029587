import React, { useState } from "react";
import s from "./Header.module.css";
import { Link, NavLink } from "react-router-dom";
import Search from "./Search/Search";
import Setting from "./Setting/Setting";
import { useTranslation } from "react-i18next";
import ListCategories from "./ListCategories/ListCategories";
import ListCollections from "./ListCollections/ListCollections";
import { useSelector } from "react-redux";
import NavMobile from "./Navmobile/NavMobile";

const Header = () => {
  const { t } = useTranslation();
  const [showCategories, setShowCategories] = useState(false);
  const [showCollections, setShowCollections] = useState(false);
  const { company } = useSelector((state) => state?.info);
  const [value, setValue] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const hide = (name) => {
    setOpen(false);
    if (name === "focus") {
      setShowCategories(false);
      setShowCollections(false);
    } else {
      setShowCategories(false);
      setShowCollections(false);
      hideSearchBar();
    }
  };

  const hideSearchBar = () => {
    setValue("");
    setShowSearchBar(false);
  };

  return (
    <header>
      <div className={s.container}>
        <div
          className={`${s.burgerMenu} ${open ? s.active : ""}`}
          onClick={() => handleClick()}
        >
          <div className={s.burgerMenuLine}></div>
          <div className={s.burgerMenuLine}></div>
          <div className={s.burgerMenuLine}></div>
        </div>
        <div className={s.logo_block}>
          <Link onClick={hide} to={"/"}>
            <img
              title={`${company?.name} Home`}
              src={`https://dearmelingerie.com:4443${company?.logo}`}
              alt="logo"
            />
          </Link>
        </div>

        <nav className={`${s.nav} ${open ? s.active : ""}`}>
          <ul className={s.menu}>
            <li>
              <NavLink
                to={"/collections"}
                onMouseOver={() => {
                  setShowCategories(false);
                  setShowCollections(true);
                }}
                onClick={hide}
                className={({ isActive }) =>
                  isActive ? `${s.menu_item} ${s.active}` : s.menu_item
                }
              >
                {t("header.collections")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/categories"}
                onMouseOver={() => {
                  setShowCollections(false);
                  setShowCategories(true);
                }}
                onClick={hide}
                className={({ isActive }) =>
                  isActive ? `${s.menu_item} ${s.active}` : s.menu_item
                }
              >
                {t("header.categories")}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/discounts"}
                onClick={hide}
                className={({ isActive }) =>
                  isActive ? `${s.menu_item} ${s.active}` : s.menu_item
                }
              >
                {t("header.discounts")}
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={hide}
                to={"/gift-certificate"}
                className={({ isActive }) =>
                  isActive ? `${s.menu_item} ${s.active}` : s.menu_item
                }
              >
                {t("header.gift_certificate")}
              </NavLink>
            </li>
          </ul>
        </nav>

        <NavMobile open={open} setOpen={setOpen} />

        <Search
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
          setShowSearchBar={setShowSearchBar}
          showSearchBar={showSearchBar}
          hideSearchBar={hideSearchBar}
          value={value}
          setValue={setValue}
          hide={hide}
          t={t}
        />
        <Setting t={t} hide={hide} />
      </div>
      {
        <ListCategories
          show={showCategories}
          setShow={setShowCategories}
          setOpen={setOpen}
        />
      }
      {
        <ListCollections
          show={showCollections}
          setShow={setShowCollections}
          setOpen={setOpen}
        />
      }
    </header>
  );
};

export default Header;
