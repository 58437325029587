import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./slices/cartSlice";
import sizesSlice from "./slices/sizesSlice";
import categoriesSlice from './slices/categoriesSlice';
import productsSlice from "./slices/productsSlice";
import collectionsSlice from "./slices/collectionsSlice";
import infoSlice from "./slices/infoSlice";
import homeSlice from "./slices/homeSlice";
import favorSlice from "./slices/favorSlice";
import orderSlice from "./slices/orderSlice";



const store = configureStore({
    reducer: {
        home: homeSlice,
        cart: cartSlice,
        sizes: sizesSlice,
        categories: categoriesSlice,
        collections: collectionsSlice,
        products: productsSlice,
        info: infoSlice,
        favorites: favorSlice,
        order: orderSlice,
    },
    devTools: false
})

export default store;