import axios from "axios";


const instanse = axios.create({
    baseURL: 'https://dearmelingerie.com:4443',
    headers: {
        "Content-Type": "application/json",
    }
})


export const dearMeApis = {
    getCategories() {
        return instanse.get('/ru/api/category/categories/')
    },
    getCategoryById(id) {
        return instanse.get(`/ru/api/category/categories/${id}`)
    },
    getCollections() {
        return instanse.get('/ru/api/category/collections/')
    },
    getCollectionById(id) {
        return instanse.get(`/ru/api/category/collections/${id}`)
    },
    getBySubCategory(lang, name, category) {
        return instanse.get(`/api/product/product/?sub_${category}__name_${lang}=${name}`)
    },
    getProductById(id) {
        return instanse.get(`/api/product/product/${id}/`)
    },
    getSumCertificate() {
        return instanse.get('/ru/api/company/gift_sertificate/')
    },
    getInfoCompany() {
        return instanse.get('/ru/api/company/company/')
    },
    getImagesForSwiper() {
        return instanse.get('/ru/api/company/slider/')
    },
    getSoonInfo() {
        return instanse.get('/ru/api/category/soon/')
    },
    getHitProducts() {
        return instanse.get('/api/product/product/?is_hit=true')
    },
    getPopularProducts() {
        return instanse.get('/api/product/product/?is_popular=true')
    },
    getProductListByName(name = '') {
        return instanse.get(`/api/product/product/?search=${name}`)
    },
    getDiscounts() {
        return instanse.get('/api/product/product/get_discount_product/')
    },
    getAllCountries() {
        return instanse.get('/ru/api/company/countries/')
    },
    getRecomendList(id) {
        return instanse.get(`/api/product/product/recomendation/?collection_id=${id}`)
    },
    sendProducts(data) {
        return instanse.post(`/ru/api/cart/orders/`, data)
    },
    buyGoodsByKassa(data) {
        return instanse.post(`/ru/api/cart/pay/`, { value: data })
    }
}