import React, { useEffect } from "react";
import s from "./SizesGuide.module.css";
import close_img from '../../assets/images/close.png'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";

const SizesGuide = ({ show, hide, setShowGuide }) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (show) {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = (e) => {
        setShowGuide(false);
      };
    }
    return () => {
      if (!show) window.history.back();
      window.onpopstate = () => { };
    };
  }, [setShowGuide, show]);

  return (
    <div
      onClick={hide}
      className={`close animate__animated animate__fadeIn animate__faster ${s.sizes_wrapper}`}
    >
      <Helmet prioritizeSeoTags>
        <title>
          {t("guide.size_guide") + ' | Dear me'}
        </title>
      </Helmet>
      <div className={s.container}>
        <div className={s.inner}>
          <div className={s.sizes_header}>
            <h1>{t("guide.size_guide")}</h1>
            <img className={`close ${s.close_btn}`} onClick={hide} src={close_img} alt="close" />
          </div>
          <div className={s.items}>
            <picture>
              <source
                media="(max-width: 768px)"
                srcSet={require(`../../assets/images/lingerie_mobile_${i18n.language}.png`)}
                sizes="567px"
              />
              <source
                srcSet={require(`../../assets/images/lingerie_${i18n.language}.png`)}
                sizes="1280px"
              />
              <img
                src={require(`../../assets/images/lingerie_${i18n.language}.png`)}
                alt=""
              />
            </picture>
          </div>
          <div className={s.items}>
            <picture>
              <source
                media="(max-width: 768px)"
                srcSet={require(`../../assets/images/bust_mobile_${i18n.language}.png`)}
                sizes="768px"
              />
              <source
                srcSet={require(`../../assets/images/bust_${i18n.language}.png`)}
                sizes="567px"
              />
              <img
                src={require(`../../assets/images/bust_${i18n.language}.png`)}
                alt=""
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SizesGuide;
