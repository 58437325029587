import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dearMeApis } from "../../API";
import { setError, setLoading } from "../../reused";

export const fetchByInfo = createAsyncThunk(
    'info/fetchByInfo',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getInfoCompany()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchSumCertificate = createAsyncThunk(
    'info/fetchSumCertificate',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getSumCertificate()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)




const infoSlice = createSlice({
    name: 'info',
    initialState: {
        company: {},
        sumArr: [],
        slider: [],
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchByInfo.pending, setLoading)
        addCase(fetchByInfo.fulfilled, (state, action) => {
            state.company = action.payload
            state.loading = false
        })
        addCase(fetchByInfo.rejected, setError)
        addCase(fetchSumCertificate.pending, setLoading)
        addCase(fetchSumCertificate.fulfilled, (state, action) => {
            state.sumArr = action.payload
            state.loading = false
        })
        addCase(fetchSumCertificate.rejected, setError)
    }
})


export default infoSlice.reducer