import React, { useEffect } from "react";
import s from "./SearchBar.module.css";
import empty from "../../../../assets/images/empty_search.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SearchBar = ({ arr, value, hideSearchBar, setSearchOpen }) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;

  console.log(arr);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.className !== "searchBar") {
        hideSearchBar();
      }
    });
    return () => {
      document.removeEventListener("click", () => { });
    };
  }, [hideSearchBar]);

  return (
    <div className={`searchBar ${s.searchbar_wrapper}`}>
      <div className={s.container}>
        {arr?.length > 0 ? (
          <div className={s.products_block}>
            {arr?.slice(0, 3)?.map((el) => (
              <Link
                onClick={() => {
                  hideSearchBar();
                  setSearchOpen(false);
                }}
                key={el?.id}
                className={s.card}
                to={`/detail-product/${el?.id}/${el[`name_${lang}`]}`}
              >
                <div className={s.image_block}>
                  <div className={s.bg}></div>
                  <img
                    className={s.img}
                    src={`https://dearmelingerie.com:4443${el.image}`}
                    alt={el[`name_${lang}`]}
                  />
                </div>
                <div className={s.info_block}>
                  <h4 title={el[`name_${lang}`]}>
                    {el[`name_${lang}`].length > 20 ? el[`name_${lang}`]?.slice(0, 20) + '...' : el[`name_${lang}`]}
                  </h4>
                  <span>${el?.total_price}</span>
                </div>
              </Link>
            ))}
            <Link
              onClick={() => {
                hideSearchBar();
                setSearchOpen(false);
              }}
              to={"/search-list"}
              className={s.all_results}
            >
              {t("search.results")}
            </Link>
          </div>
        ) : (
          <div className={s.empty_block}>
            <img src={empty} alt="Empty" />
            {lang === "ru" ? (
              <p>По запросу “{value}” ничего не найдено...</p>
            ) : (
              <p>No results found for "{value}"...</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
