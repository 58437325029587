import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Suspense } from 'react';
import Loader from '../Loader/Loader';
const Home = lazy(() => import('../../Pages/Home/Home'))
const Collections = lazy(() => import('../../Pages/Collections/Collections'))
const NotFound = lazy(() => import('../../Pages/NotFound/NotFound'))
const Checkout = lazy(() => import('./../../Pages/Checkout/Checkout'))
const Favorites = lazy(() => import('./../../Pages/Favorites/Favorites'))
const Discounts = lazy(() => import('./../../Pages/Discounts/Discounts'))
const GiftCertificate = lazy(() => import('../../Pages/GiftCertificate/GiftCertificate'))
const Categories = lazy(() => import('../../Pages/Categories/Categories'))
// const CategoryList = lazy(() => import('../../Pages/Categories/CategoryList/CategoryList'))
const ProductsList = lazy(() => import('../../Pages/ProductsList/ProductsList'))
const Detail = lazy(() => import('./../../Pages/Detail/Detail'))
// const CollectionsList = lazy(() => import('../../Pages/Collections/CollectionsList/CollectionsList'))
const SearchList = lazy(() => import('../../Pages/SearchList/SearchList'))
const PopularsPage = lazy(() => import('../../Pages/PopularsPage/PopularsPage'))
const CheckoutDone = lazy(() => import('./../CheckoutDone/CheckoutDone'))
const Policy = lazy(() => import('../../Pages/Policy/Policy'))




const Main = () => {
    return (
        <main>
            <Routes>
                <Route path='/' element={<Suspense fallback={<Loader />}><Home /></Suspense>} />
                <Route path='/categories' element={<Suspense fallback={<Loader />}><Categories /></Suspense>} />
                <Route path='/search-list' element={<Suspense fallback={<Loader />}><SearchList /></Suspense>} />
                {/* <Route path='/categories/:category_id/:category_name' element={<Suspense fallback={<Loader />}><CategoryList /></Suspense>} /> */}
                <Route path='/collections' element={<Suspense fallback={<Loader />}><Collections /></Suspense>} />
                {/* <Route path='/collections/:collection_id/:collection_name' element={<Suspense fallback={<Loader />}><CollectionsList /></Suspense>} /> */}
                <Route path='/products/:list_name/:name/:id' element={<Suspense fallback={<Loader />}><ProductsList /></Suspense>} />
                <Route path='/detail-product/:id/:product_name' element={<Suspense fallback={<Loader />}><Detail /></Suspense>} />
                <Route path='/order' element={<Suspense fallback={<Loader />}><Checkout /></Suspense>} />
                <Route path='/thanks/jhfbugdfhhgvrfesrug' element={<Suspense fallback={<Loader />}><CheckoutDone /></Suspense>} />
                <Route path='/favorites' element={<Suspense fallback={<Loader />}><Favorites /></Suspense>} />
                <Route path='/discounts' element={<Suspense fallback={<Loader />}><Discounts /></Suspense>} />
                <Route path='/populars-list' element={<Suspense fallback={<Loader />}><PopularsPage /></Suspense>} />
                <Route path='/gift-certificate' element={<Suspense fallback={<Loader />}><GiftCertificate /></Suspense>} />
                <Route path='/pages/:page_name' element={<Suspense fallback={<Loader />}><Policy /></Suspense>} />
                <Route path='*' element={<Suspense fallback={<Loader />}><NotFound /></Suspense>} />
            </Routes>
        </main>
    );
};

export default Main;