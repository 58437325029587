import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { dearMeApis } from "../../API";
import { setError, setLoading } from "../../reused";

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getCategories()
            if (res.status !== 200) {
                throw new Error('Server Error, can\'t resolve categories')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchCategoryById = createAsyncThunk(
    'categories/fetchCategoryById',
    async (id, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getCategoryById(id)
            if (res.status !== 200) {
                throw new Error('Server Error, can\'t resolve category')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        loading: false,
        categoriesArr: [],
        category: {},
        error: null,
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchCategories.pending, setLoading)
        addCase(fetchCategories.fulfilled, (state, action) => {
            state.loading = false
            state.categoriesArr = action.payload
        })
        addCase(fetchCategories.rejected, setError)
        addCase(fetchCategoryById.pending, setLoading)
        addCase(fetchCategoryById.fulfilled, (state, action) => {
            state.loading = false
            state.category = action.payload
        })
        addCase(fetchCategoryById.rejected, setError)
    }
})


export default categoriesSlice.reducer