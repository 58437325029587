import { createSlice } from "@reduxjs/toolkit";
import { setLS } from "../../localStorage";


const sizesSlice = createSlice({
    name: 'sizes',
    initialState: {
        ownSizes: {
            bust: '',
            under_bust: '',
            waist: '',
            hip: '',
            height: '',
        },
        use_default: false,
    },
    reducers: {
        changeSizes(state, action) {
            state.ownSizes[action.payload.key] = action.payload.value
        },
        setSizesFromLS(state, action) {
            state.ownSizes = action?.payload
        },
        toggleDefault(state) {
            state.use_default = !state.use_default
            setLS('use_default', state.use_default)
        },
        setDefaultFromLs(state, action) {
            state.use_default = action?.payload
        }
    },
})

export const { changeSizes, setSizesFromLS, toggleDefault, setDefaultFromLs } = sizesSlice.actions

export default sizesSlice.reducer;