import React, { useEffect, useState } from 'react';
import basket from '../../../assets/images/Shape.png'
import favorites from '../../../assets/images/Like.png'
import s from './Setting.module.css'
import i18n from '../../../i18n';
import Cart from '../../Cart/Cart';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


const Setting = ({ t, hide }) => {
    const [isVisible, setIsVisible] = useState(false)
    const { countInCart } = useSelector(state => state.cart)
    const { countInFavor } = useSelector(state => state.favorites)
    const toggleLanguage = (lang) => {
        i18n.changeLanguage(lang)
    };

    const hideCart = (e) => {
        if (e.target.classList[0] === 'close') {
            setIsVisible(false)
        }
        if (e.keyCode === 27) {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', hideCart)
    }, [])
    return (
        <div className={s.setting_block}>
            <div onClick={() => setIsVisible(true)} className={s.btn_basket_block}>
                <img title={t("header.basket_title")} src={basket} alt="basket" />
                {countInCart > 0 && <span className={s.cart_count}>{countInCart}</span>}
            </div>
            {isVisible ? <Cart hide={hideCart} setIsVisible={setIsVisible} show={isVisible} /> : null}
            <div
                onClick={hide}
                className={s.btn_favorites_block}>
                <Link to={'/favorites'}>
                    <img title={t("header.favorites_title")} src={favorites} alt="favorites" />
                </Link>
                {countInFavor > 0 && <span className={s.cart_count}>{countInFavor}</span>}
            </div>
            <div className={s.lang_block}>
                <select defaultValue={i18n.language} onChange={(e) => toggleLanguage(e.target.value)} className={s.select_lang}>
                    <option value="ru">RU</option>
                    <option value="en">EN</option>
                </select>
            </div>
        </div>
    );
};

export default Setting;