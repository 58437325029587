import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { dearMeApis } from "../../API"
import { setError, setLoading } from "../../reused"

export const fetchBySlider = createAsyncThunk(
    'home/fetchBySlider',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getImagesForSwiper()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByHit = createAsyncThunk(
    'home/fetchByHit',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getHitProducts()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchByPopular = createAsyncThunk(
    'home/fetchByPopular',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getPopularProducts()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchSoonInfo = createAsyncThunk(
    'info/fetchSumCertificate',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getSoonInfo()
            if (res.status !== 200) {
                throw new Error('Server error')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        slider: [],
        hits: [],
        soon: {},
        populars: [],
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchBySlider.pending, setLoading)
        addCase(fetchBySlider.fulfilled, (state, action) => {
            state.slider = action.payload
            state.loading = false
        })
        addCase(fetchBySlider.rejected, setError)
        addCase(fetchByHit.fulfilled, (state, action) => {
            state.hits = action.payload
            state.loading = false
        })
        addCase(fetchByHit.rejected, setError)
        addCase(fetchByPopular.fulfilled, (state, action) => {
            state.populars = action.payload
            state.loading = false
        })
        addCase(fetchByPopular.rejected, setError)
        addCase(fetchSoonInfo.fulfilled, (state, action) => {
            state.soon = action.payload
            state.loading = false
        })
        addCase(fetchSoonInfo.rejected, setError)
    }
})

export default homeSlice.reducer