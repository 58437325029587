import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { dearMeApis } from "../../API"
import { setError, setLoading } from "../../reused"

export const fetchCollections = createAsyncThunk(
    'collections/fetchCollections',
    async (_, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getCollections()
            if (res.status !== 200) {
                throw new Error('Server Error, can\'t resolve collections')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)

export const fetchCollectionById = createAsyncThunk(
    'collections/fetchCollectionById',
    async (id, { rejectWithValue }) => {
        try {
            const res = await dearMeApis.getCollectionById(id)
            if (res.status !== 200) {
                throw new Error('Server Error, can\'t resolve collection')
            }
            return res.data
        } catch (error) {
            return rejectWithValue(error.message)
        }
    }
)


const categoriesSlice = createSlice({
    name: 'collections',
    initialState: {
        loading: false,
        collectionsArr: [],
        collection: {},
        error: null,
    },
    reducers: {},
    extraReducers: ({ addCase }) => {
        addCase(fetchCollections.pending, setLoading)
        addCase(fetchCollections.fulfilled, (state, action) => {
            state.loading = false
            state.collectionsArr = action.payload
        })
        addCase(fetchCollections.rejected, setError)
        addCase(fetchCollectionById.pending, setLoading)
        addCase(fetchCollectionById.fulfilled, (state, action) => {
            state.loading = false
            state.collection = action.payload
        })
        addCase(fetchCollectionById.rejected, setError)
    }
})


export default categoriesSlice.reducer