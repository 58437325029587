import React, { useEffect } from 'react';
import s from './Cart.module.css'
import close_img from '../../assets/images/close_img.png'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CartCard from './CartCard/CartCard';
import CartEmpty from './CartEmpty/CartEmpty';
import { Link } from 'react-router-dom';
import { totalSumInCart } from '../../reused';
import { Helmet } from 'react-helmet-async';

const Cart = ({ hide, setIsVisible, show }) => {
    const { i18n, t } = useTranslation()
    const { cartArr } = useSelector(state => state?.cart)
    const lang = i18n.language


    useEffect(() => {
        if (show) {
            window.history.pushState(null, '', window.location.href)
            window.onpopstate = (e) => {
                setIsVisible(false)
            }
        }
        return () => {
            if (!show)
                window.history.back()
            window.onpopstate = () => { }
        }
    }, [setIsVisible, show])
    return (
        <div onClick={hide} className={`close animate__animated animate__fadeIn animate__faster  ${s.cart_wrapper}`}>
            <Helmet prioritizeSeoTags>
                <title>
                    {lang === 'ru' ? 'Корзина | Dear me' : 'Cart | Dear me'}
                </title>
            </Helmet>
            <div className={s.cart_visible}>
                <div className={s.cart_container}>
                    <div className={s.cart_top_block}>
                        <h2>{t("cart.title")}</h2>
                        <img onClick={hide} className={`close ${s.close_btn}`} src={close_img} alt="close" />
                    </div>

                    <div className={s.cart_cards_wrapper}>
                        {
                            cartArr?.length > 0 ?
                                cartArr?.map(el => <CartCard setIsVisible={setIsVisible} key={el.id_item} el={el} />)
                                :
                                <CartEmpty />
                        }
                    </div>
                    <div className={s.cart_bottom_block}>
                        {
                            cartArr?.length > 0 ?
                                <div className={s.order_info_block}>
                                    <div className={s.total_block}>
                                        <span className={s.total_text}>{t("cart.total")}</span>
                                        <span className={s.total}>${totalSumInCart(cartArr)}</span>
                                    </div>
                                    <Link to={'/order'} onClick={() => setIsVisible(false)}>
                                        <button className={s.shopping_btn}>{t("cart.checkout")}</button>
                                    </Link>
                                </div>
                                :
                                <div className={s.go_shopping}>
                                    <Link to={'/categories'} onClick={() => setIsVisible(false)}>
                                        <button className={s.shopping_btn}>{t("cart.go_shopping")}</button>
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;