import React, { useEffect, useState} from "react";
import s from "./Search.module.css";
import searchIcon from "../../../assets/images/search.png";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanSearch,
  fetchProductsByName,
} from "../../../redux/slices/productsSlice";
import SearchBar from "./SearchBar/SearchBar";
import MobileSearchBar from "./MobileSearchBar/MobileSearchBar";

const Search = ({
  t,
  hide,
  value,
  hideSearchBar,
  showSearchBar,
  setShowSearchBar,
  setValue,
  searchOpen,
  setSearchOpen
}) => {
  const dispatch = useDispatch();
  const { searchArr } = useSelector((state) => state?.products);

  const handleSearch = (e) => {
    setValue(e.target.value);
    if (e.target.value.trim().length && e.target.value.length > 2) {
      !showSearchBar && setShowSearchBar(true);
      const newValue = value[0]?.toUpperCase() + value?.slice(1);
      dispatch(fetchProductsByName(newValue));
    }
    if (e.target.value.length < 3) {
      dispatch(cleanSearch());
      showSearchBar && setShowSearchBar(false);
    }
  };

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
 
  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div className={s.search_block}>
      <input
        className={s.search_input}
        onChange={handleSearch}
        value={value}
        onFocus={() => hide("focus")}
        type="search"
        placeholder={t("header.search")}
      />
      <button onClick={() => viewportWidth <= 767 && setSearchOpen(true)} className={s.search_btn}>
        <img src={searchIcon} alt="search" />
      </button>

      {showSearchBar && (
        <SearchBar
        setSearchOpen={setSearchOpen}
        arr={searchArr} value={value} hideSearchBar={hideSearchBar}
        />
      )}

      {searchOpen && (
        <MobileSearchBar
        hideSearchBar={hideSearchBar}
        setValue={setValue}
        handleSearch={handleSearch}
        showSearchBar={showSearchBar}
        setSearchOpen={setSearchOpen}
        value={value}
        t={t}
        />
      )}
    </div>
  );
};

export default Search;
