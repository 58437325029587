import React from 'react';
import s from './ListCategories.module.css'
import 'animate.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ListCategories = ({ show, setShow, setOpen }) => {
    const { i18n } = useTranslation()
    const lang = i18n.language

    const { categoriesArr, error } = useSelector(state => state.categories)
    const hide = (e) => {
        if (e.target.classList[0] === 'modal') {
            setShow(false)
        }
    }
    const toClose = () => {
        setShow(false)
        setOpen(false)
    }
    return (
        <div
            onMouseOver={hide}
            style={{ display: show ? 'block' : 'none' }}
            className={`modal ${s.list_bg_fone} animate__animated animate__fadeIn animate__faster`}
        >

            <div className={s.list_wrapper}>
                <div className={s.container}>
                    {
                        categoriesArr.length > 0 ?
                            categoriesArr?.map(el => (
                                <div key={el?.id}>
                                    <Link onClick={() => toClose()} to={`/products/category/${el[`name_${lang}`]}/${el.id}`}>
                                        <h2 className={s.category}>{el[`name_${lang}`]}</h2>
                                    </Link>
                                    {/* {
                                        el?.sub_categories?.map(item => (
                                            <Link
                                                className={s.sub_category}
                                                onClick={() => toClose()}
                                                to={`/sub_category/category/${el[`name_${lang}`]}/${item[`name_${lang}`]}`}
                                                key={item.id}
                                            >
                                                {item[`name_${lang}`]}
                                            </Link>
                                        ))
                                    } */}
                                </div>
                            ))
                            :
                            <h1 className={s.category}>{error}</h1>
                    }
                </div>
            </div>
        </div>
    );
};

export default ListCategories;