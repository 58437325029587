import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/index';
// import { PayPalScriptProvider } from '@paypal/react-paypal-js';

// const initialOptions = {
//   "client-id": "AWfuKQ2ULgTELPxX1RiRvlji9TQMCQojnS7TvrLJa7nkrw3DuXn3hzIF8TBmDHrGOrckaVGI-dbob7e5",
//   "currency": "USD",
//   "intent": "capture",
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <PayPalScriptProvider options={initialOptions}>
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </PayPalScriptProvider>

);

